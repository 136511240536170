<template lang="pug">
v-container
  v-toolbar.mb-2(
    collapse
    style="bottom: 1em; right: 1em"
    color="deep-purple darken-1"
    dark
  )
    span Edit
  v-row
    v-col(cols="4")
      v-text-field(
        label="Stock"
        :value="stockId"
        disabled
      )
    v-col(cols="4")
      v-text-field(
        label="Quantity"
        v-model="quantity"
      )
    v-col(cols="4")
      v-autocomplete(
        label="Unit"
        v-model="unit"
        :items="units"
      )
    v-col(cols="4")
      v-text-field(
        label="Price/Unit"
        v-model="unitPrice"
      )
    v-col(cols="4")
      v-text-field(
        label="Discount"
        v-model="discount"
      )
    v-col(cols="4")
      v-text-field(
        label="Order Status"
        :value="orderDetailStatusId"
        disabled
      )
    v-col(cols="4")
      v-text-field(
        label="Date Allocated"
        v-model="dateAllocated"
        type="date"
      )
    //- v-col(cols="4")
    //-   v-text-field(
    //-     label="Purchase Order"
    //-     v-model="purchaseOrderId"
    //-   )
    //- v-col(cols="4")
    //-   v-text-field(
    //-     label="Inventory"
    //-     v-model="inventoryId"
    //-   )
    v-col(cols="12")
      v-row
        v-col(cols="2")
          v-btn(
            block
            tile
            color="success"
            dark
            @click="updateDetail"
          )
            span Update
        v-col(cols="2")
          v-btn(
            block
            tile
            color="blue-grey darken-1"
            dark
            @click="$emit('close')"
          )
            span Cancel
</template>
<script>
const inputVars = () => ({
  stockId: null,
  quantity: null,
  unit: null,
  unitPrice: null,
  discount: null,
  orderDetailStatusId: null,
  dateAllocated: null,
  purchaseOrderId: null,
  inventoryId: null,
})

let currentIndex = null

export default {
  name: 'EditOrderDetail',
  props: {
    stocks: {
      type: Array,
      default: () => [],
    },
    defaultValues: {
      type: Object,
      default: inputVars,
    },
  },
  data: () => ({
    ...inputVars(),
    units: ['kg', 'bag', 'egg', 'lot', 'loan'],
  }),
  watch: {
    unit() {
      this.changeUnitPrice()
    },
  },
  methods: {
    changeUnitPrice() {
      const { unit, stockId } = this
      if (!stockId || !unit) return
      const stock = this.stocks.find(item => item.id === stockId)
      this.setUnitPrice({ unit, stock })
    },
    setUnitPrice({ unit, stock }) {
      const defaultUnit = this.defaultValues.unit || null
      const defaultUnitPrice = this.defaultValues.unitPrice || null
      if (unit === defaultUnit) {
        this.unitPrice = defaultUnitPrice
        return
      }
      if (unit === 'bag') {
        this.unitPrice = stock.feed_detail.resell_per_bag || null
        return
      }
      this.unitPrice = stock.feed_detail.resell_per_kilo || null
    },
    populateInputs(data) {
      this.defaultValues = data
      Object.keys(data).forEach(key => {
        this[key] = data[key]
      })
    },
    setCurrentIndex(index) {
      currentIndex = index
    },
    updateDetail() {
      const inputData = this.getInputData()
      this.$emit('update-detail', inputData, currentIndex)
      this.reset()
    },
    getInputData() {
      const result = {}
      Object.keys(inputVars()).forEach(key => {
        result[key] = this[key]
      })
      return result
    },
    reset() {
      currentIndex = null
      const defaults = inputVars()
      Object.keys(defaults).forEach(key => {
        this[key] = defaults[key]
      })
    },
  },
}
</script>
